<template>
  <v-layout style="margin: 0px; padding: 0px; width: 100%;">
    <v-row>
      <v-col col="12">
        <v-row wrap>
          <v-col cols="9" offset="2">
            <v-row>
              <v-col cols="10">
                <router-link to="/" style="text-decoration: none;color: black;">
                  <v-icon>mdi-arrow-left-circle-outline</v-icon>
                  <span class="ml-5"> Return to Home </span>
                </router-link>
              </v-col>
            </v-row>

            <v-row style="margin-top:70px;">
              <v-col cols="12" align="center">
                <router-link to="/">
                  <v-img
                    class="centerImg2"
                    src="https://res.cloudinary.com/ecrtechdev/image/upload/v1632485866/B2BLogo4_dnvcnf.png"
                    height="100"
                    width="120"
                  />
                </router-link>
              </v-col>
            </v-row>

            <v-row style="margin-top:5px;">
              <v-col cols="12">
                <router-link to="/">
                  <v-img
                    class="centerImg2"
                    src="https://res.cloudinary.com/ecrtechdev/image/upload/v1634566675/oops_images_nmjlij.jpg"
                    height="100%"
                    width="50%"
                  />
                </router-link>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" align="center" justify="center">
                <span class="titleStyleColor">Unathroized</span>
                <span class="titleStyle"> Page</span><br />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
export default {
  name: "ResetPasswordLink",

  data: () => ({
    loader: false,
    checkbox: false,
    modalSuccess: false,
    errorMessage: "",
    show3: false,
    formData: {
      email: "",
    },
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
  }),
  methods: {
    signin() {
      this.loader = true;
      this.errorMessage = "";
      this.$http
        .post(`${process.env.VUE_APP_URL}password/send`, this.formData)
        .then((response) => {
          if (response.data.success == true) {
            this.modalSuccess = true;
            this.loader = false;
          }
        })
        .catch((error) => {
          this.loader = false;
          this.errorMessage = error.response.data.data;
        });
    },
    redirectLogin() {
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
}

.loginPadding {
  margin: 0px !important;
  padding: 0px !important;
  background: url("https://res.cloudinary.com/ecrtechdev/image/upload/v1632485906/loginImage_blv0va.png")
    no-repeat center right fixed !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}

.titleStyle {
  width: 411px;
  font-family: ABeeZee;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
}

.titleStyleColor {
  width: 411px;
  font-family: ABeeZee;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  background: -webkit-linear-gradient(#72bd61, #111212);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.centerImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  text-align: center;
}

.centerImg2 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  text-align: center;
}

.buttonStyle {
  width: auto;
  height: auto;
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 14px;
}
</style>
